import { head, pipe, ifElse, propOr, isEmpty, always, find, propEq } from 'ramda'
import debounce from 'lodash.debounce'

export const getHeadErrorOrEmptyObj = pipe(
  propOr([], 'errors'),
  ifElse(isEmpty, always({}), head)
)

export const validateField = (schema, values) =>
  debounce(
    (name, callback = () => {}) =>
      schema
        .validateAt(name, values)
        // eslint-disable-next-line standard/no-callback-literal
        .then(() => callback({ valid: true, errors: [] }))
        .catch(callback),
    200
  )

export const validateValues = schema =>
  debounce((values, callback) => schema.isValid(values).then(callback), 400)

export const preventSubmitOnEnter = keyEvent => {
  if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
    keyEvent.preventDefault()
  }
}

export const getOptionByValue = value => options => pipe(
  find(
    propEq('value', value)
  )
)(options)
