import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getHeadErrorOrEmptyObj } from 'src/utils/form'
import { Input as TextField } from 'de-suite-me-ui'

export const Input = props => {
  const {
    name,
    type,
    onChange,
    validate,
    label,
    value: initialValue,
    required,
    disabled,
    reset
  } = props
  const [touched, _setTouched] = useState(false)
  const [value, _setValue] = useState(initialValue || '')
  const [{ valid, error }, _validate] = useState({
    valid: true,
    error: {}
  })

  const { t } = useTranslation()

  useEffect(() => {
    if (touched && !reset) {
      validate(name, v => {
        _validate({ valid: v.valid, error: getHeadErrorOrEmptyObj(v) })
      })
    }
  }, [value, touched, reset])

  useEffect(() => {
    _setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    if (reset) {
      _setValue(initialValue || '')
    }
  }, [reset])

  const handleFocus = () => _setTouched(true)
  const handleChange = e => {
    _setValue(e.target.value)
    onChange(name, e.target.value)
  }

  return (
    <TextField
      disabled={disabled}
      required={required}
      name={name}
      label={label}
      value={value}
      errorText={valid || disabled ? '' : t(error.key, error.options)}
      type={type}
      error={!valid && !disabled}
      onFocus={handleFocus}
      onChange={handleChange}
    />
  )
}

Input.defaultProps = {
  onChange: () => {},
  validate: () => {}
}

export default Input
