import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Form from 'src/components/atoms/Form'
import Input from 'src/components/atoms/Input'
import {
  validateLoginField,
  validateLoginValues
} from 'src/features/auth/duck/schema'
import Button from 'src/components/atoms/Button'
import styled from 'styled-components'

import { loginSupervisorRoutine } from 'src/features/auth/duck/actions'
import { getIsAuthLoading } from 'src/features/auth/duck/selectors'

const emptyValues = {
  email: '',
  password: ''
}

export const LogInForm = props => {
  //  REDUX
  const dispatch = useDispatch()
  const isLoading = useSelector(getIsAuthLoading)
  const loginSupervisor = useCallback(
    payload => dispatch(loginSupervisorRoutine(payload)),
    [dispatch]
  )

  const [valid, _validateSchema] = useState(false)
  const [values, setValues] = useState(emptyValues)

  useEffect(() => {
    validateLoginValues(values, _validateSchema)
  }, [values])

  const { t } = useTranslation()
  const handleOnChange = (name, value) =>
    setValues({ ...values, [name]: value })

  const handleSubmit = e => {
    e.preventDefault()
    loginSupervisor(values)
  }

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Input
          required
          name='email'
          label={t('form.email.label')}
          onChange={handleOnChange}
          validate={validateLoginField(values)}
        />
        <Input
          required
          name='password'
          type='password'
          label={t('form.password.label')}
          onChange={handleOnChange}
          validate={validateLoginField(values)}
        />
        <ButtonContainer>
          <Button
            disabled={isLoading || !valid}
            onClick={handleSubmit}
            variant='contained'
            color='primary'
          >
            {t('logInPage.loginCTA')}
          </Button>
        </ButtonContainer>
      </Form>
    </>
  )
}

export default LogInForm

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 20px;
`
