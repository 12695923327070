import React from 'react'
import styled from 'styled-components'
import Layout from 'src/theme/Layout'
import Grid from '@material-ui/core/Grid'
import LogIn from 'src/features/auth/LogIn'
import Paper from 'src/components/atoms/Paper'
import i18n from 'src/providers/i18n'
import { useTranslation } from 'react-i18next'

export default () => {
  const { t } = useTranslation()

  return (
    <>
      <Layout>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} />
          <Grid item xs={12} sm={4}>
            <LoginContainer>
              <Title>{t('logInPage.pageTitle')}</Title>
              <Paper>
                <LogIn />
              </Paper>
            </LoginContainer>
          </Grid>
        </Grid>
      </Layout>
    </>
  )
}

const LoginContainer = styled.div`
  margin-top: 50px;
`

const Title = styled.h1`
  margin: 20px auto;
  text-align: center;
`
