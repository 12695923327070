import { API_STATES } from 'src/utils/api'
import { propEq, propOr } from 'ramda'
import { createSelector } from 'reselect'
import LocalStorageService from 'src/services/LocalStorageService'
import { KEYS } from 'src/utils/localStorage'
import { isNotNilOrEmpty } from 'src/utils/ramda'

export const selectAuth = state => state.auth

export const getIsAuthLoading = createSelector(
  selectAuth,
  propEq('state', API_STATES.IN_PROGRESS)
)

export const isAuthLoaded = createSelector(
  selectAuth,
  propEq('state', API_STATES.DONE)
)

export const getIsAuthorised = () =>
  isNotNilOrEmpty(LocalStorageService.get(KEYS.token))

export const getAuthUser = createSelector(selectAuth, propOr({}, 'user'))
