import { string, object } from 'yup'
import {
  validateValues,
  validateField
} from 'src/utils/form'

// Log in form validator

export const loginSchema = object().shape({
  email: string()
    .required(() => ({ key: 'form.email.errors.required' }))
    .email(() => ({ key: 'form.email.errors.invalid' })),
  password: string().required(() => ({ key: 'form.password.errors.required' }))
})

export const validateLoginField = values => validateField(loginSchema, values)
export const validateLoginValues = validateValues(loginSchema)
